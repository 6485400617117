@import 'variables.scss';

.panel {
  padding: 12px;
}

.colorPicker {
  width: 100% !important;
}

.layerMoveContainer {
  display: flex;
  justify-content: space-around;
}

.layerMove {
  font-size: 3rem;
}

.textButton {
  &:focus {
    background: $primary;
    border: none !important;
    box-shadow: none !important;
  }
  &:active {
    background: $primary;
  }
}
