.count {
  font-size: 1.1rem;
  font-weight: 900;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info {
  display: grid;
  grid-template-columns: 24px 1fr 24px;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.typeahead {
  input {
    height: 22px !important;
    font-size: 12px;
    width: 84px !important;
    text-align: center;
  }
}
