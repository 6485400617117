@import '~bootstrap/scss/bootstrap.scss';

.topGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  margin-bottom: 3rem;
}

.actionRow {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 1rem;
  margin-bottom: 2rem;
}

.actionIcon {
  opacity: 0.4;
}

@include media-breakpoint-up(sm) {
  .actionRow {
    grid-template-columns: 100px 1fr 50px;
  }
}

@include media-breakpoint-up(xl) {
  .topGrid {
    grid-template-columns: 2fr 3fr;
  }
}
