@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.logoContainer {
  height: 150px;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form {
  width: 100%;
  max-width: 500px;
}

.form input {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

@include media-breakpoint-up(lg) {
  .row {
    height: 100%;
  }

  .logoContainer {
    height: 100%;
    margin-bottom: 0;
  }
}
