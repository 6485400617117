@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.overlayContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
}

@include media-breakpoint-up(xl) {
  .overlayContainer {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 4px;
  }
}
