@import '~bootstrap/scss/bootstrap.scss';

.iframeLandscape {
  width: 256px;
  height: 144px;
}

.iframeLandscape329 {
  width: 512px;
  height: 144px;
}

.iframeLandscape98 {
  width: 256px;
  height: 228px;
}

.iframePortrait {
  width: 144px;
  height: 256px;
}

.iframePortrait329 {
  width: 72px;
  height: 256px;
}

.iframeLandscape89 {
  width: 228px;
  height: 255px;
}

@include media-breakpoint-up(sm) {
  .iframeLandscape {
    width: 384px;
    height: 216px;
  }

  .iframePortrait {
    width: 216px;
    height: 384px;
  }
}

@include media-breakpoint-up(lg) {
  .iframeLandscape {
    width: 640px;
    height: 360px;
  }
}
