@import 'variables.scss';

.itemNotSelected {
  width: 100%;
  transition: all 0.2s ease-in-out;
  position: relative;

  &:hover {
    transform: scale(1.1);
  }
}

.libraryItem {
  object-fit: contain;
}

.itemSelected {
  width: 100%;
  position: relative;
  scale: 1.1;
  filter: drop-shadow(2px 2px 6px #ccc);

  &:before {
    position: absolute;
    background: $success;
    padding-top: 2px;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    box-shadow: 0px 3px 3px #0000003b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $successText;
    top: 6px;
    left: 6px;
    content: '✓';
    z-index: 999;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  background: rgba(255, 255, 255, 0);
  border: solid 3px $primary;

  &:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.5);
  }
}

.itemContent {
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 3px 3px #0000003b;
}

.selected {
  border: solid 3px $success;
}

.notSelected {
  border: solid 3px $primary;
}
