@import '~bootstrap/scss/bootstrap.scss';

@include media-breakpoint-down(lg) {
  .grid {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

@include media-breakpoint-down(md) {
  .grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@include media-breakpoint-down(sm) {
  .grid {
    grid-template-columns: 1fr !important;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}
