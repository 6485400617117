@import '~bootstrap/scss/bootstrap.scss';

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
}

@include media-breakpoint-up(md) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@include media-breakpoint-up(lg) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@include media-breakpoint-up(xl) {
  .grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (min-width: 1400px) {
  .grid {
    grid-template-columns: repeat(8, 1fr);
  }
}
