@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import 'scss/buttons.scss';
@import 'scss/fonts.scss';

html,
body,
#root {
  height: 100%;
}

body {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

.sidenav .active {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  transition: all 0.2s linear;
  background: $primary;
}

.cursor-pointer {
  cursor: pointer;
}

.round-icon-button {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;

  &:hover {
    background: $dark;
    color: #fff;
    border: solid 2px $dark;
  }
}

.breadcrumb {
  background-color: white;
  margin: 0;
  padding: 0;

  & * {
    margin: 0;
    color: $primary-dark !important;
  }
}

.nav-tabs {
  & .nav-link {
    background-color: $primary-dark;
    color: white;
    border: none;

    &.active {
      background-color: $primary !important;
      color: white;
      border: none;
      border-bottom: 3px solid $main-lightest;
    }

    &:hover {
      background-color: $primary;
      color: white;
      border: none;
      border-bottom: 3px solid $main-lightest;
    }
  }
}

.list-group-item-action {
  color: $body-color;
}

.dropdown-menu {
  min-width: 12px;
  text-align: center;
}
