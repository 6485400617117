$drawerWidth: 260px;

$primary: #0d9bd8;
$primary-dark: #0b80b3;
$primary-gradient: linear-gradient(0.25turn, #0fadf1, #0fadf1);

$body-color: #004269; // text color

$main-darkest: #144681;
$main-dark: #2c6cad;
$main: #549acf;
$main-light: #73b4e0;
$main-lightest: #b8def2;

$body-bg: #eef2f9;
$success: #72db6c;
$successText: white;
$info: $main-light;
