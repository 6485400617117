.container {
  max-width: 100vh;
}

.konvaContainer {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: 1rem;
}

.sizerLandscape {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.sizerLandscape329 {
  width: 100%;
  position: relative;
  padding-top: 28.125%; /* 32:9 Aspect Ratio */
}

.sizerLandscape98 {
  width: 100%;
  position: relative;
  padding-top: 88.8888888889%; /* 9:8 Aspect Ratio */
}

.sizerPortrait {
  width: 100%;
  position: relative;
  padding-top: 177.77777778%; /* 9:16 Aspect Ratio */
}

.sizerPortrait932 {
  width: 100%;
  position: relative;
  padding-top: 355.555555556%; /* 9:32 Aspect Ratio */
}

.sizerPortrait89 {
  width: 100%;
  position: relative;
  padding-top: 112.5%; /* 9:8 Aspect Ratio */
}

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
