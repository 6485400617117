@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

.font-size-lg {
  font-size: 2rem;
}

.font-size-xl {
  font-size: 3rem;
}
