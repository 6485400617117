@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;

  & .item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    width: 100%;
    padding: 12px;
    background: $primary-dark;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 3px #0000003b;
    border-radius: 10px;
    cursor: pointer;
    border: solid 3px $primary-dark;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: $primary;
      border: solid 3px $main-lightest;
    }
  }
}

.mediaModal {
  max-width: 90% !important;
}

@include media-breakpoint-up(xl) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}
